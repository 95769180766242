header.Header__Container {
  height: 10vh;
  min-height: 50px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 2.5rem;
  margin: 0 1.5rem;

  border-bottom: 0.2px solid #e2e2e2;
}

header.Header__Container img {
  height: 2.2rem;
}

header.Header__Container ul.Header__Menu {
  display: flex;

  gap: 2.5rem;
}

header.Header__Container ul.Header__Menu li {
  display: flex;
  align-items: center;
}

header.Header__Container ul.Header__Menu li:hover {
  cursor: pointer;
}

header.Header__Container ul.Header__Menu li > span {
  font-size: 1.25rem;
  color: #6d6d6d;
}

header.Header__Container ul.Header__Menu li.Header_Menu--selected > span {
  font-weight: 600;
  color: #0049c4;
  border-bottom: 1px solid #0049c4;
}

header.Header__Container ul.Header__Menu li > img:not(:last-child) {
  width: 1.5rem;
}

header.Header__Container ul.Header__Menu li:last-child > img {
  width: 2rem;
  height: auto;
  display: block;
}

header.Header__Container ul.Header__Menu li:not(:last-child) > img {
  margin-right: 0.5rem;
}
