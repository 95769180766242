.no-scroll {
  overflow: hidden;
}

.step__title {
  font-size: 1rem;
}

.initial_data_container {
  //height: 80%;
}

.initial_data_form {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 3rem;
}

.initial_data_form .group_form {
  display: flex;
}

.initial_data_form .group_form .item_group {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
}

.initial_data_form .group_form .item_group input {
  width: 85%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: 0.2s;
  outline: none;
}

.initial_data_form .group_form .item_group input:focus {
  border: 1px solid #1769e4;
  box-shadow: 0px 0px 4px #1769e442;
}

.types_list {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  //gap: 1rem;
  margin-top: 0.5rem;
  justify-content: space-between;
}

.types_list .type_item {
  background-color: #fff;
  border: 2px solid #fff;
  width: 7.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-shadow: 0px 0px 8px #67666a34;
  border-radius: 10px;
  font-weight: 600;
  color: #202024;
  cursor: pointer;
  transition: 0.2s;
}

.types_list .selected_item {
  background-color: #e0fef1;
  border: 2px solid #13845e;
  color: #064a38;
  font-weight: 700;
}

/* segunda-tela-css */

.automacao_config_container {
  height: 100%;
}

.automacao_config_form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;
}

.create_trigger {
  box-sizing: border-box;
  display: flex;
  gap: 0.5rem;
  background-color: #aca9a92e;
  width: 100%;
  padding: 10px;
  border-radius: 7px;
  /* box-shadow: 0px 0px 5px #c4bebe45; */
  font-weight: 600;
  cursor: pointer;
}

.create_trigger .icon {
  color: #1769e4;
}

.trigger_list {
  overflow-y: auto;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  max-height: 450px;
  height: 450px;
  width: 100%;
}

/* item trigger */

.trigger_item {
  box-sizing: border-box;
  background-color: #fff;
  padding: 15px;
  border-radius: 7px;
  width: 100%;
  box-shadow: 0px 0px 5px #c4bebe45;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

.delete_trigger {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  border: 0;
  color: #a22040;
}

.trigger_title {
  font-size: 1.1rem;
  margin-bottom: 10px;
  font-weight: 600;
}

.select_type_trigger {
  display: flex;
  gap: 1rem;
}

.select_type_trigger .type_option {
  background-color: #ededed;
  padding: 10px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;
  border: 2px solid #ededed;
  transition: 0.2s;
}

.select_type_trigger .selected_item {
  background-color: #e0fef1;
  border: 2px solid #13845e;
  color: #064a38;
  font-weight: 700;
}

.select_path_trigger {
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-top: 20px;
}

.select_path_trigger .path_from,
.select_path_trigger .path_to {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.actions_container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.add_action {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #cccccc4e;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
  font-weight: 600;
}

.actions_list {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.remove_action {
  background-color: #fff;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.action_item {
  display: flex;
  background-color: #fff;
  padding: 15px;
  border-radius: 7px;
  border: 1px solid #ccc;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.action_item_form {
  width: 90%;
  display: flex;
  gap: 0.5rem;
}

.action_item_form .group_action {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.gatilho_section {
}

.gatilho_div {
  display: flex;
  gap: 16px;
  margin-top: 8px;
  background-color: #ecebeb;
  padding: 10px;
  border-radius: 7px;
}

.gatilho_title {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}

.gatilho_subtitle {
  font-weight: 500;
  margin: 0;
}

.gatilho_example {
  font-size: 12px;
  color: #838387;
}

.gatilho_card {
  display: flex;
  gap: 8px;
}

.inputDaysPeriod {
  outline: 0;
  padding: 2px 8px;
  border: none;
  border-right: 1px solid #ccc;
  width: 100%;
}

.inputDaysPeriod::placeholder {
  font-size: 14px;
}

.daysPeriodSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  min-height: 38px;
  border-radius: 4px;
}

.buttonsInput {
  display: flex;
  flex-direction: column;
  padding: 0px 8px;
}

.group1 {
  width: 45%;
}

.group2 {
  width: 55%;
}

.action_item_form .group_action span {
  font-weight: 600;
  color: #20242b;
}
