.paginationContainer {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 30px auto 0px auto;
}

.Buttons {
    padding: 7px 12px;
    margin: 5px 8px;
    border-radius: 100px;
    border: 1px solid #bbb;
    color: #383838;
    cursor: pointer;
    font-weight: bold;
  }
  
  .Buttons:hover {
    color: white;
    background-color: #1768e4;
    border: 1px solid #1768e4;
  }

  .AnteriorBttn{
    padding: 7px 9px 7px 7px
  }

  .ProximoBttn{
    padding: 7px 7px 7px 9px;
  }

  .AnteriorBttn, .ProximoBttn{
    margin: 5px;
    border-radius: 100px;
    border: 1px solid #bbb;
    color: #383838;
  }

  .AnteriorBttn:hover, .ProximoBttn:hover{
    background-color: #bbb;
    cursor: pointer;
  }

  .paginationActive a {
    color: white;
    background-color: #1768e4;
    border: 1px solid #1768e4;
  }
  
  .paginationDisabled a {
    color: #bbb;
    background-color: #eee;
  }

  .paginationDisabled a:hover {
    color: #bbb;
    background-color: #eee;
    cursor: auto;
  }
