/* Style the accordion section */
.accordion__section {
    display: flex;
    flex-direction: column;
  }
  
  .accordion__alt {
    border-radius: 7px;
    box-shadow: 0 2px 10px 0 rgba(255, 255, 255, 0.5);
    background-color: #d6d6d6;
  
    cursor: pointer;
    height: 40px;
    padding: 12px 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    /* border: 1px solid #ccc; */
    transition: background-color 0.6s ease;
  }
  
  .accordion__arrowContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 70%;
  }
  
  /* .accordion:hover,
  .active {
    background-color: #ccc;
  } */
  
  .accordion__title__alt {
    font-weight: bold;
    font-size: 18px;
  }
  
  .accordion__subtitle__alt {
    font-weight: 300;
    font-size: 12px;
  }
  
  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }
  
  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(90deg);
    transition: all ease-in 0.2s;
  }
  
  /* Style the accordion content panel. Note: hidden by default */
  .accordion__container__alt {
    margin-top: -0.9rem;
    border-radius: 0 0 7px 7px;
  
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  
    background-color: #d6d6d6;

  
    overflow: hidden;
   
    transition: max-height 0.6s ease;
  }

  .active_container {
    /* border: 1px solid #ccc; */
    border-top: 0;
  }
  
  .accordion__content__alt {
    padding: 10px;
  }


  