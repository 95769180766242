.VersionUpdate {
  position: absolute;
  top: 180px;
  height: 437px;
  width: 750px;
  background-color: rgba(149, 149, 149, 0.397) !important;
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px) !important;
  border-radius: 20px;
  right: 6.8vw;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}

.VersionUpdate h3 {
  margin: 0;
  font-size: 18px;
}

.VersionUpdate--LeftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px !important;
  padding-right: 20px;
  padding-bottom: 15px;
  width: 35%;
  justify-content: space-between;
}

.VersionUpdate--Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
}

.VersionUpdate--Container h3 {
  margin: 10px 0 0 0;
}

.colaborators {
  margin: 35px 0px;
  max-height: 100%;
  padding: 5px;
  overflow: auto;
  width: 100%;
  height: 100%;
  color: #d4d4d4;
  align-items: center;
}

.Center {
  justify-content: center;
}
.Start {
  justify-content: start;
}

.Header {
  gap: 0;
}

.colaborator {
  font-size: 11px;
}

.version {
  font-size: 11px;
  color: #d4d4d4;
  text-decoration: none;
}

.version:hover {
  color: #fff;
}

.Brand {
  padding: 0;
  gap: 0;
}

.BrandName {
  font-size: 14px;
  padding: 0;
}

.VersionUpdate--RightContainer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  background-color: rgba(0, 0, 0, 0.702);
  color: #d4d4d4;
  padding: 20px;
  border-radius: 15px;
  max-width: 461px;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
}

.VersionUpdate--Title {
  font-weight: 600;
  font-size: 20px;
}

.VersionUpdate--Description {
  font-size: 14px;
}

.VersionUpdate--Items {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin-top: 30px;
}

.MinorItem--Title {
  font-size: 15px;
}
.Item--Description {
  font-size: 12px;
}

.Onboarding__AnimaTopVersion {
  opacity: 1;
  transition: 1s;
  transform: translateY(600px);
  animation: animarPage 1s forwards;
}

/* Telas de notbooks menores */
@media (max-height: 700px) {
  .VersionUpdate {
    top: 130px;
  }
}

/* Telas de */

@media (min-height: 800px) {
  .VersionUpdate {
    top: 235px;
  }
}

@media (min-height: 950px) {
  .VersionUpdate {
    top: 265px;
    right: 13vw;
  }
}
