.opHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 20px;
}

.resOpHeader {
  display: none;
}

/* h1 {
  font-size: 27px;
} */

/* .modalButton {
  padding: 12px 15px 0px 15px;
  height: 30px;
  background-color: #1768e4;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  border: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 110px;
  text-align: center;
} */

.modalButton:hover {
  background-color: #1276b9;
  color: #ffffff;
  cursor: pointer;
}

.opBody {
  display: flex;
}

@media only screen and (max-width: 398px) {
  .opHeader .modalButton {
    display: none;
  }

  /* .opHeader h2 {
    margin-top: 0.83em;
    margin-bottom: 0.4em;
  } */

  .resOpHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px 10px 20px;
  }
}
