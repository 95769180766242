.DashboardUserMenu {
  z-index: 3;
  position: absolute;
  right: 1rem;
  top: 6rem;
  width: 180px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.9375rem;

  border-radius: 10px;

  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(217, 217, 217, 0.5);

  padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;

  cursor: default;
}

.DashboardUserMenu::before {
  content: "";
  position: absolute;
  right: 3rem;
  top: -0.6rem;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
  filter: drop-shadow(0px -4px 2px rgba(236, 236, 236, 0.486));
  clear: both;
}

.DashboardUserMenu__Header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
}

.DashboardUserMenu img.DashboardUserMenu__Img {
  display: block;
  height: auto;

  width: 3.75rem;
}

.DashBoardUserMenu__Header-Title {
  display: block;
  /* text-transform: lowercase; */
  font-size: 16px;
  font-weight: 600;
}

.DashBoardUserMenu__Header-Subtitle {
  font-weight: 300;
  font-size: 12px;
}

.DashboardUserMenu__Divider {
  width: 174px;
  height: 1px;

  background-color: #ccc;
}

.DashboardUserMenu__Strings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 0.1rem;
}

.DashboardUserMenu {
  padding: 1.5625rem;
}

.DashboardUserMenu__Content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.DashboardUserMenu__Content button {
  font-size: 14px;
  font-weight: bold;
  color: #0a0a0a;
}

.DashboardUserMenu__Content button:not(.DashboardUserMenu__ButtonLeave) {
  border-radius: 10px;
  width: 174px;
  height: 31px;

  background-color: #f4f4f4;
}

.DashboardUserMenu__ButtonLeave {
  background-color: transparent;
  border: 0;
}
