.tabela_descontos--container {
  padding: 15px;
}

.tabela__tabs {
  display: flex;
  gap: 0.7rem;
  margin: 0.5rem 0 1rem 0;
}

.tabela__tabs button {
  border: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  height: 30px;
  min-width: 120px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 0px 5px #e6d8d8;
  background-color: #fff;
  color: #27272c;
}

.tabela__tabs button span {
  padding: 0 15px;
}

.tabela__tabs .active {
  font-weight: 600;
  background-color: rgba(200, 216, 246, 0.555);
  color: rgb(24, 24, 182);
  transition: 0.2s;
  /* box-shadow: 0px 0px 5px #cbc8d9; */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  text-align: left;
  color: rgb(19, 19, 35);
}

.table td {
  padding: 1rem 0;
  border-bottom: 1px solid #ccc;
  color: rgb(19, 19, 35);
}

.table .col_status,
.table .col_acoes {
  text-align: center;
}

.table .col_status {
  width: 20%;
}
.table .col_acoes {
  width: 15%;
  padding-right: 1.5rem;
}
.table .col_config {
  width: 13%;
  text-align: center;
  padding-left: 1rem;
}

.criar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.criar--body {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.criar--inputs {
  display: flex;
  gap: 1rem;
}

.criar--inputs .input_container {
  width: 100%;
  max-width: 50%;
}

.input_criar {
  height: 25px;
  display: block;
  padding: 5px;
  width: 95%;
  border: 1px solid #ccc;
  margin-top: 0.3rem;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.input_criar_error {
  height: 25px;
  display: block;
  padding: 5px;
  width: 95%;
  border: 1px solid #f12148;
  margin-top: 0.3rem;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.spanError {
  color: #f12148;
  font-size: 12px;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  opacity: 0;
}

.select_criar {
  width: 99%;
  margin-top: 0.3rem;
  /* height: 10px; */
}
.multiselect_criar {
  width: 99%;
  /* height: 25px; */
  /* padding: 5px; */
  margin-top: 0.3rem;
}

.multiselect_criar .dropdown-container {
  height: 38px;
}

.multiselect_criar .dropdown-heading {
  margin-top: 3px;
}

.criar--footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
