.SettingsMenu__Container {
    border-right: 0.2px solid #e2e2e2;
  
    margin-top: 1rem;
  
    padding-top: 1.75rem;
    padding-left: 4.1875rem;
    padding-right: 1rem;
  }
  
  .SettingsMenu__Container > h1 {
    font-size: 2.5rem;
    margin: 0;
  }

  .SettingsMenu__SubMenu {
    margin-top: 2rem;
  }
  
  .SettingsMenu__SubMenu span {
    font-size: 0.9375rem;
    color: #838387;
    line-height: 2rem;
    padding-left: 0.5rem;
  }
  
  .SettingsMenu__SubMenu ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .SettingsMenu__SubMenu li {
    display: flex;
    align-items: center;
  
    padding: 0.5rem;
    border-radius: 8px;
  
    font-weight: 500;
  
    cursor: pointer;
    font-size: 1rem;
  }
  
  .SettingsMenu__SubMenu li > img, .SettingsMenu__SubMenu li > svg {
    margin-right: 0.5rem;
  }
  
  .SettingsMenu__SubMenu li.SettingsMenu__SubMenu--selected,
  .SettingsMenu__SubMenu li.SettingsMenu__SubMenu--selected:hover,
  .SettingsMenu__SubMenu li.SettingsMenu__SubMenu--selected:hover svg {
    background-color: #c8ddff;
  
    color: #0049c4;
    fill: #0049c4;
  
    font-weight: bold;
  }
  
  .SettingsMenu__SubMenu li:hover,
  .SettingsMenu__SubMenu li:hover svg {
    background-color: #f2f2f2;
  
    /* fill: #f2f2f2; */
  }
  