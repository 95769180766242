.card_onboard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 241px;
  padding: 20px;
  border-radius: 1.125rem;
  box-shadow: 0 2px 10px #d9d9d980;
  box-sizing: border-box;
}

.card_onboard span {
  color: #353535;
}

.span__title {
  font-size: 1.25rem;
}

.span__content {
  font-size: 0.75rem;
  font-weight: 300;
  text-align: center;
  white-space: pre-line;
  width: 100%;
}
