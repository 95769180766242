@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap");

/* .accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
} */

.accordion{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.accordion-header {
  margin-top: 0;
  margin-bottom: 0;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.35rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 15px;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.accordion-body .edit__valor__filiacao {
  margin: 0.75rem;
}

.accordion-body #editar {
  margin: 0;
}

.accordion-body .item {
  display: flex;
  align-items: center;
  margin: 0.75rem 0;
  /* background-color: red; */
  width: 100%;
}

.accordion-body .item .icon {
  color: #000;
  margin-right: 2px;
  padding: 0;
  margin-right: 10px;
  display: block;
  height: 15px;
  width: 15px;
}


.botao-editar {
  margin-left: 0.5rem;
  border-radius: 7px;
  border: 0;
  background-color: #bebed157;
  color: #323038;
  padding: 0.15rem 0.3rem;
  transition: 0.3s;
}

.botao-editar:hover {
  background-color: #b0b0c2c5;
}

.input-valor[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  padding: 0.5rem;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;

}

.input-valor {
  border-radius: 7px;
  border: 1px solid #ccccddc2;
  transition: 0.3s;
  /* margin: 0 0.4rem; */
}

.percentage_input {
  margin-right: .6rem;
  margin-bottom: 10px;
  margin-top: .3rem;
}
.percentage_input input {
  margin-right: .6rem;
}
.money_input {
  margin-bottom: 10px;
  margin-top: .3rem;
}
.money_input input {
  margin: 0 .6rem;
}

.input-valor:focus {
  border: 1px solid #5271d6e5;
  outline: none;
}

.novo-valor{
  color: rgb(42, 41, 49);
  font-weight: 600;
  /* margin-left: 1rem;
  margin-right: 5px; */
}

.btn-valor {
  padding: 0.5rem 0.75rem;
  margin-right: 0.35rem;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  border-radius: 7px;
  border: 0;
}

.salvar {
  background-color: #18a0aa;
  font-weight: 700;
  color: #fff;
  transition: 0.3s;
}

.salvar:hover {
  background-color: #155974;
}

.cancelar {
  color: #b82046;
  background-color: transparent;
  transition: 0.3s;
}

.cancelar:hover {
  color: #6e103c;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  /* height: 176px; */
  overflow: hidden;
  transition: height 0.35s ease;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230c63e4%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}


.selecionado button {
  font-weight: 700;
  color: #22aad3;
  border: 1px solid rgb(34, 170, 211);
  border-radius: 0.25rem;
  box-shadow: inset 0 -1px 5 rgb(34, 170, 211);
  transition: transform 0.2s ease-in-out;

}

.selecionado button:not(.collapsed){
  border: 1px solid #0c63e4;
  border-radius: 0.25rem 0.25rem 0 0;
}

@media only screen and (max-width: 1230px) {
  .accordion-body #editar, .accordion-body #editar span, .accordion-body #editar #input-wrapper{
    display: block;
  }

  .accordion-body #editar span{
    margin: 0;
  }

  .accordion-body #editar #input-wrapper{
    padding: 0.75rem 0;
  }

  .accordion-body .input-valor{
    width: 40%;
  }
}

@media only screen and (max-width: 1230px) {
  .accordion-body #editar, .accordion-body #editar span, .accordion-body #editar #input-wrapper{
    display: block;
  }

  .accordion-body #editar span{
    margin: 0;
  }

  .accordion-body #editar #input-wrapper{
    padding: 0.75rem 0;
  }

  .accordion-body .input-valor{
    width: 40%;
  }
}
