label {
  color: #534545;
  font-weight: 700;
  margin: 3px 0px;
  font-size: 13px;
}
.card-bg {
  flex: 0 0 86%;
  max-width: 86%;

}

.blank_size {
  width: 100%;
  height: 100%;
  position: fixed;
}

.blank_size[data-v-396648d4] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.img_bg {
  z-index: -1;
  position: fixed;

  display: flex;
  width: 100%;
  flex: 1;
  height: 100%;
  /* object-fit: contain; */
}
