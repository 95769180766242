.dropdown {
  position: relative;
}

/* *,
::after,
::before {
  box-sizing: border-box;
} */

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.btn-group-sm > .btn,
.btn-sm {
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 4px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

button,
select {
  text-transform: none;
}

.dropdown-menu.show {
  display: block;
}
.dropdown-menu {
  position: absolute;
  z-index: 10;
  display: none;
  min-width: 10rem;
  padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(217, 217, 217, 0.5);
}

.dropdown-item {
  display: block;
  width: 100%;
  /* padding: 4px 16px; */
  clear: both;
  margin: 0;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  line-height: normal;

  font-size: -apple-system, BlinkMacSystemFont, sans-serif;
}
