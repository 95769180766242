.Login__RouterContainer {
  position: relative;
  overflow: hidden;
}

.ImageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login__RouterContainer header {
  position: absolute;
  top: 15px;
  left: 30px;
  z-index: 1;
}

.Login__Logo {
  width: 9.5rem;
  height: 4.375rem;
}
.LoginCredentials__Container {
  display: grid;
  grid-template-columns: 4fr 8fr;

  height: 100vh;

  overflow: hidden;
}

.LoginCredentials__Formulario {
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  padding: 1rem 2.375rem 1rem 2.375rem;

  height: 100vh;
  box-sizing: border-box;
}

.LoginCredentials__Formulario--Title {
  font-size: 3.75rem;
  font-weight: 600;
  color: #000;
  margin: 0;
  max-width: 25rem;
}

.LoginCredentials__Formulario--Subtitle {
  font-size: 1rem;
  font-weight: 300;
  color: #2c2c2c;
  margin: 12px 0 0 0;
  max-width: 15.75rem;
}

.LoginCredentials__FormularioBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 18px;
  box-shadow: 0 2px 10px 0 rgba(217, 217, 217, 0.5);
  margin: 0 auto;
  width: 100%;
  margin-top: -6.5rem;
  padding-top: 40px;
  /* max-width: 21.8125rem; */
}

.LoginCredentials__Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -1.5rem;
  width: 100%;
  padding: 3.25rem 1.625rem;
  box-sizing: border-box;
}

.LoginCredentials__FormularioBox img {
  margin-top: -7rem;
}

.LoginCredentials__Form--Field {
  width: 100%;
}

.LoginCredentials__Form--Field:first-child {
  margin-bottom: 1.5rem;
}

.LoginCredentials__Form input {
  display: block;

  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;

  width: 100%;

  border: 0;
  border-radius: 11px;
  outline: 0;
  outline-color: #f0f0f0;
  padding: 0.5rem 0;

  background: #f0f0f0;
  transition: all 0.3s ease;
}

.LoginCredentials__Form input:focus {
  border: solid 1px #0049c4;
}

.LoginCredentials__Form #user,
.LoginCredentials__Form #password {
  height: 40px;
}

.LoginCredentials__Form--Field:nth-child(2),
.LoginCredentials__Form button {
  margin-bottom: 2rem;
}

.LoginCredentials__FormularioBox--Link {
  font-size: 0.875rem;
  color: #6d6d6d;
}

input.LoginCredentials__Form--has-error {
  border: solid 1px red;
}

.LoginCredentials__Form--Error {
  display: block;

  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  color: red;
}

.LoginCredentials__Form input::placeholder {
  color: #dadada;
}

.LoginCredentials__Form button[type="submit"] {
  width: 100%;
  height: 40px;

  border-radius: 10px;
  background: #0049c4;
  color: #fff;
  border: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

@media screen and (max-height: 800px) {
  .LoginCredentials__Formulario--Title {
    margin-top: 5rem;
  }

  .LoginCredentials__FormularioBox {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 1180px) {
  .LoginCredentials__Container {
    grid-template-columns: 1fr;
    height: 100%;
  }

  .LoginCredentials__Formulario {
    padding: 1rem;
    height: 100vh;
    justify-content: space-around;
  }

  .LoginCredentials__Formulario--Title {
    font-size: 2rem;
    margin: 5rem 0 0 0;
  }

  .LoginCredentials__Formulario--Subtitle {
    width: auto;
    margin-bottom: 1rem;
  }

  .LoginCredentials__Form--Inputs input {
    /* font-size: 4.5rem; */
    max-width: none;
    font-size: 1rem;
  }

  .LoginCredentials__Form button[type="submit"] {
    margin-top: 1rem;
  }
}

.img_starwars {
  background-image: url(../assets/wallpaper.jpg);
  background-size: cover;
  background-position: 100% 50%;
}

@media screen and (min-width: 1600px) {
  .LoginCredentials__Formulario--Title {
    max-width: none;
  }

  .LoginCredentials__Formulario--Subtitle {
    width: auto;
  }

  .LoginCredentials__Form input {
    max-width: none;
  }
}

@media screen and (max-width: 1180px) {
  .Login__RouterContainer header {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
