.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
}
.form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}
.form-check-input[type=checkbox] {
    border-radius: 0.25em;
}

.form-check .form-check-input {
    float: left;
    margin-right: 0.5rem; 
}

.form-check-input {
    width: 1.2em;
    height: 1.2em;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
    appearance: none;
}

.preco{
    white-space: nowrap;
}
/* 
form{
    margin: 0;
} */

