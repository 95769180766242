
.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  z-index: 100;
  
}
.content {
  position: absolute;
  top: 38%;
  left: 50%; 

  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  overflow: hidden;
  outline: none;
  /* padding: 20px; */
  width: 528px;
  /* height: 560px; */
  margin: 0 auto;
  
    /* margin: 6rem auto 0 auto; */
  }

  .SettingsModal__Container {
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    padding: 2.1875rem 30px 20px;
    height: 100%;
    margin: 0 auto

}

.SettingsModal__Avatar {
  width: 3.25rem;
  opacity: 0.2;

  transition: all 0.5s ease-out;
}


.SettingsModal__Header {
  position: relative;

  display: flex;
  gap: 1.5rem;

  border-bottom: solid 1px #e2e2e2;

  padding-bottom: 1rem;
}

.SettingsModal__Header--title,
.SettingsModal__Header--subtitle {
  opacity: 0.2;
  transition: all 0.5s ease-out;
}

.SettingsModal__Close {
  position: absolute;
  top: -20px;
  right: 0px;

  opacity: 0.7;

  cursor: pointer;
}

.SettingsModal__Close:hover {
  opacity: 1;
  transition: all 0.3s ease-in;
}

.SettingsModal__Header--title {
  font-size: 1.375rem;
  font-weight: bold;
  color: #353535;
}

.SettingsModal__Header--subtitle {
  color: #353535;
  font-size: 0.75rem;
  font-weight: 300;
}

.SettingsModal__Form--Step {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  background-color: #fff;
  margin-top: 1.25rem;

  padding: 1.25rem 1.5625rem;

  border-radius: 10px;

  box-shadow: 0 2px 10px 0 rgba(217, 217, 217, 0.5);
}

.SettingsModal__Form--Row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.SettingsModal__Form--Col {
  width: 100%;
}

.SettingsModal__Form--Field {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  width: 100%;
}

.SettingsModal__Form--Label {
  font-size: 0.75rem;
  font-weight: 300;
}

.SettingsModal__Form--Label-withImg {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.SettingsModal__StatusSelect--Row {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 2rem;
}

.SettingsModal__StatusSelect--Option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  margin-top: 0.5rem;
}

.SettingsModal__StatusSelect--Option:hover,
.SettingsModal__StatusSelect--Radio:hover {
  cursor: pointer;
}

.SettingsModal__Main-Table--status {
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  font-size: 10px;
  font-weight: bold;
  text-align: center;

  border-radius: 9px;

  line-height: 1rem;
  width: 4.75rem;

  height: 18px;
}

.SettingsModal__StatusSelect--Option:nth-child(1)
  .SettingsModal__Main-Table--status {
  background-color: #0049c4;
}
.SettingsModal__StatusSelect--Option:nth-child(2)
  .SettingsModal__Main-Table--status {
  background-color: #00b065;
}
.SettingsModal__StatusSelect--Option:nth-child(3)
  .SettingsModal__Main-Table--status {
  background-color: #4d4d4d;
}

.SettingsModal__StatusSelect--Radio {
  width: 15px;
  height: 15px;
  /* padding: 0; */
  border: 1px solid #000;
  /* outline: solid 1px #00b065; */
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-radius: 50%;
  transition-duration: 0.3s;
  /* background-color: #fff; */
}

.SettingsModal__StatusSelect--Radio:checked {
  border: 1px solid #000;
  background-color: #00b065;
}

.SettingsModal__StatusSelect--Radio:checked:focus {
  border: 1px solid #00b065;
}

.SettingsModal__StatusSelect--Radio:not(:checked):focus {
  border: 1px solid #00b065;
}

.SettingsModal__Form--Field
  input:not(.SettingsModal__StatusSelect--Radio, .SettingsModal__ComboBox, .check-category),
.SettingsModal__Form--Field
  select:not(.SettingsModal__StatusSelect--Radio, .SettingsModal__ComboBox) {
  display: block;

  font-size: 0.875rem;
  font-weight: normal;

  height: 2.1875rem;
  /* width: 100%; */

  border: 1px solid #f0f0f0;
  border-radius: 8px;
  outline: 0;
  outline-color: #f0f0f0;
  padding: 0 1rem;

  background: #f0f0f0;
  transition: all 0.3s ease;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.SettingsModal__Form--Field select.SettingsModal__ComboBox {
  background-image: url('../../../Home/assets/icon-arrow-select.svg');
  background-position: calc(100% - 15px) calc(1em - 2px),
    calc(100% - 2.5em) 0.5em;
  background-size: 12px 12px, 12px 12px, 1px 1.5em;
  background-repeat: no-repeat;
}

.SettingsModal__Form input:focus {
  border: solid 1.5px #0049c4;
}

.SettingsModal__Form--Field input::placeholder {
  color: #c9c9c9;
}

input.SettingsModal__Form--has-error {
  border: solid 1px red;
}

.SettingsModal__Form--Error {
  display: block;

  font-size: 0.8rem;
  font-weight: 600;
  color: red;
}

.SettingsModal__Form--Row > button {
  margin: 0 auto;
}

.SettingsModal__Container button[type='submit'] {
  width: 7.5rem;
  height: 1.5625rem;

  margin-top: 1.25rem;

  border-radius: 5px;
  background: #00b065;
  color: #fff;

  font-size: 0.875rem;
  font-weight: 600;
}

.SettingsModal__Container button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.SettingsModal__Form .SettingsModal__Header .SettingsModal__BlackColor,
.SettingsModal__Header .SettingsModal__BlackColor {
  color: #000;
  opacity: 1;

  transition: all 0.5s ease-in;
}

.SettingsUsersModal__Archive--Container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  /* max-width: 400px; */
  height: 100%;
  padding: 20px 0;
  height: 360px;

  margin: 0 auto;

  justify-content: center;
  align-items: center;

  text-align: center;
}

.SettingsUsersModal__Archive--Container.TransferProfile__Container {
  height: auto;
}

.SettingsUsersModal__Archive--Title {
  font-size: 1.6rem;
  font-weight: bold;
  color: #353535;
}

.SettingsUsersModal__Archive--Subtitle {
  color: #353535;
  font-size: 1.125rem;
  font-weight: 300;
}

.SettingsUsersModal__Archive--Subtitle span {
  display: inline-block;
}

.SettingsUsersModal__Archive--Container button {
  width: 150px;
  height: 40px;

  margin-top: 1rem;

  border-radius: 10px;
  background: #0049c4;
  color: #fff;

  font-size: 1.25rem;
  font-weight: 600;
}

.SettingsUsersModal__Archive--Container button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.SettingsUsersModal__Archive--Buttons {
  display: flex;
  gap: 1rem;
}

.SettingsUsersModal__Archive--Buttons > button:first-child {
  background-color: #f12148;
}

.SettingsModal__MultiSelect--Label,
.SettingsModal__Form--Label {
  font-size: 12px;
  color: #000;
  /* font-weight: bold; */
}

.SettingsModal__Form--Checkbox{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.75rem 0;
}

.SettingsModal__Form--OptionCheck{
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
