.CardSelect {
  border: solid 3px transparent;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  cursor: pointer;
  /* background-color: red; */
  height: 24em;
  width: 320px;
}

.CardSelect__Container {
  background-color: #fff;
  border-radius: 10px 10px 0 0;

  display: flex;
  flex-direction: column;

  height: 100%;

  gap: 1.5rem;

  padding: 2rem 1rem 1rem 1rem;

  justify-content: center;
  align-items: center;

  box-shadow: 0 2px 10px 0 rgba(217, 217, 217, 0.5);
}

.CardSelect__Container header {
  display: flex;
  flex-direction: column;

  gap: 1.5rem;

  justify-content: center;
  align-items: center;
}

.CardSelect__HeaderText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CardSelect__HeaderText .CardSelect__Title {
  font-size: 1.125rem;
  font-weight: bold;
}

.CardSelect__HeaderText .CardSelect__Subtitle {
  font-size: 0.875rem;
}

.CardSelect__Content {
  font-size: 14px;
  font-weight: 300;

  text-align: center;
  padding: 0 0.5rem;
}

.CardSelect__Divider {
  width: 75%;
  height: 1px;
  background-color: #c6c6c6;
}

.CardSelect__Selected {
  border: solid 3px #00b065;
  border-radius: 10px;

  transition: all 0.1s ease-in;
}

.CardSelect__Selected .CardSelect__Checkbox {
  background-color: #00b065;

  transition: all 0.1s ease-in;
}

.CardSelect__Checkbox {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1.3125rem 0;
  background-color: #c6c6c6;
  border-radius: 0 0 10px 10px;
}

.CardSelect__Checkbox input {
  display: block;
  width: 1rem;
  height: 1rem;

  appearance: none;
  -webkit-appearance: none;

  border-radius: 50%;
  border: 3px solid #fff;

  background: #fff;

  box-shadow: 0 0 0 1px #6d6d6d;
}

.CardSelect__Checkbox input:checked {
  box-shadow: 0 0 0 1px #00b065;
  background-color: #00b065;
}

.CardSelect__Checkbox:has(input:checked) {
  border-radius: 0;
}
