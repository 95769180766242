  .modalBody {
    margin-top: 5px;

  }

  .flex-modalBody{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
  }

  .flex-modalBody form {
    /* background-color: red; */
    width: 100%;
  }

  .modalBody .flex_label{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modalBody .flex_label button{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #fff;
    border: none;
    background-color: #AAAAAA;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 600;
    /* text-decoration: underline; */
    padding: 0.4rem;
    border-radius: 0.4rem;
  }

  .modalBody .flex_label button:hover{
    background-color: #6F717A;
  }


  .modalBody input, .modalBody select{
    margin-top: 0.25rem;
  }

  .modalBody input{
    padding: 0.35rem;
    -moz-appearance: textfield;
  }

  .modalBody .label-select{
    text-align: start;
    margin-left: 0.75rem;
    display: flex;
    align-items: center;
  }
  
  .modalFooter {
    display: flex;
    flex: 40%;
    /* margin-top: 40px; */
    justify-content: flex-end;
    align-items: center;
    text-align: center;
  }

  .info {
    display: flex;
    flex: 40%;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;

  }
  
  .btnClose {
    /* text-align: center;
    display: inline-block;
    margin: 5px;
    padding: 7px 25px;
    background-color: #aaa;
    border: none;
    color: white;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    text-decoration: none; */

    text-align: center;
    display: inline-block;
    padding: 0.5rem 0.75rem;
    background-color: #aaa;
    border: none;
    color: white;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    text-decoration: none;
  }
  
  .btnClose:hover {
      cursor: pointer;
      background-color: rgb(82, 82, 82);
  }

  .fipeDados {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    gap: 1rem;
  }

  .fipeDados > * {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.3rem;
  }

  .fipeDados > * strong {
    color: #534545;
  }

  .fipeDados .resultado {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    background-color: #eee;
  }

  .money_symbol {
    position: absolute;
  }

  
  @media only screen and (max-width: 810px){
    .flex-modalBody{
      display: block;
    }

    .fipeDados {
      display: flex;
      flex-direction: column;
    }

    
  }



  
