.Modal__Container {
  background-color: #fff;
  margin: 11% auto 0 auto;
  padding: 25px;
  width: 33%;
  max-height: fit-content;
  border-radius: 7px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
}

.Modal__Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.Modal__Header h2 {
  margin: 0;
  font-size: 1.1rem;
}

.Modal__Body {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  margin-top: 1.3rem;
}

.Filter__Item {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 2px 10px #d9d9d980;
}

.Filter__Item .Title {
  font-weight: 600;
  color: #302f38;
}

.Filter_Vendor {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 2px 10px #d9d9d980;
}

.Filter_Vendor .Title {
  font-weight: 600;
  color: #302f38;
}

.Filter_Combobox {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.Modal__Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.3rem;
}

.BtnSubmit__Filter {
  background-color: #05ac6c;
  width: 7rem;
  height: 1.7rem;
  padding: 2px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 600;
  color: #fff;
  border: none;
  font-size: 14px;
  border-radius: 7px;
  transition: 0.2s;
}

.BtnSubmit__Filter:hover {
  background-color: #017a66;
}

.BtnSubmit__Filter:disabled {
  opacity: 0.7;
}
