.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.ModalAlert__Container {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;

  overflow: auto;

  outline: none;
  /* padding: 20px; */

  margin: 0 auto;
  width: 528px;
}

.OnboardingAlerts__Container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  max-width: 400px;
  height: 100%;
  padding: 20px;
  height: 360px;

  margin: 0 auto;

  justify-content: center;
  align-items: center;

  text-align: center;

  box-sizing: border-box;
}

.OnboardingAlerts__Title {
  font-size: 1.875rem;
  font-weight: bold;
  /* color: #353535; */
  margin: 0;
}

.OnboardingAlerts__Subtitle {
  color: #353535;
  margin: 0;
  font-weight: 300;
}

.OnboardingAlerts__Container button {
  width: 150px;
  height: 40px;
  border: none;
  margin-top: 1rem;

  border-radius: 10px;
  background: #1768e4;
  color: #fff;

  font-size: 1.25rem;
  font-weight: 600;
}
