.imp__btn_criar {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: .5rem;
    height: 30px;
    transition: .2s;
    border-radius: 4px;
    padding: 0 10px;
}

.form__active {
    border-radius: 4px 4px 0 0;
    border: 1px solid #1769e47c;
    border-bottom: none;
}

.imp__btn_criar:hover{
    background-color: #efefef;
}

.imp__btn_criar .imp__icon {
    color: #1768e4;
}

.imp__btn_criar .imp__title {
    font-weight: 600;
    color: #18161c;
}

.imp__form {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #1769e47c;
    border-top: none;
    border-radius: 0 0 4px 4px;
    animation: slideDown 0.2s ease-out forwards;
}

.imp__form .imp__form--only_input {
    width: 98%;
}

.imp__form .imp__form--double_input {
    width: 98%;
    display: flex;
    gap: 1.5rem;
}

.imp__form .imp__form--double_input .input__form,
.imp__form .imp__form--only_input .input__form {
    width: 100%;
}

.imp__form--btn {
  text-align: center;
  display: inline-block;
  margin: 5px;
  padding: 0.5rem 0.7rem;
  background-color: #388beb;
  border: none;
  color: white;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
}

.imp__form--btn:disabled{
    opacity: .7;
}

.imp__form--footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.input_valor {
  display: block;
  width: 100%;
  height: 25px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px 0px;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  padding: 2px 0px 2px 5px;
}

.imp__lista {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    margin-top: 20px;
    max-height: 200px;
    overflow-y: auto;
    
}

.imp__item--remove {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: #ffffff;
    border: none;
    color: #bb2463;
}

.imp__item {
    position: relative;

    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    color: #2e2d32;
}

.imp__item #IMP_TIPO {
    font-weight: 700;
}

.imp__item .imp__item--valores {
    font-size: 13px;
    color: #2e2d32;
}

@keyframes slideDown {
    from {
        transform: translateY(-10%);
    }
    to {
        transform: translateX(0);
    }
}