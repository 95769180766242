@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap");

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
    
}
.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #1768e4;
    font-weight: 600;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}
.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 500;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
[type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #1c191f;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}
[type="button"], [type="reset"], [type="submit"], button {
    -webkit-appearance: button;
}
button, select {
    text-transform: none;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.fade {
    transition: opacity .15s linear;
}

.fade:not(.show) {
    opacity: 0;
}

.button_continuar {
    color: #1768e4;
    text-decoration: underline;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.button_continuar:hover {
    cursor: pointer;
}


.implementos__container {
    max-height: 200px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
