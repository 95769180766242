.SettingsUsers__Container {
    display: flex;
    flex-direction: column;
    gap: 3.125rem;
  
    padding: 3rem 3rem 0 3rem;
  }
  
  .SettingsUsers__Header-SideAdd--Title > img {
    cursor: pointer;
  }
  
  .SettingsUsers__Header {
    display: grid;
    grid-template-columns: 4fr 2fr;
    justify-content: space-between;
  }
  
  .SettingsUsers__Header-SideAdd--Title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  
    font-size: 1.875rem;
    font-weight: bold;
  }
  
  .SettingsUsers__Header-SideAdd--SubTitle {
    line-height: 2rem;
    font-size: 1rem;
  }
  
  .SettingsUsers__Header-SearchBox--Form {
    width: 100%;
  }
  
  .SettingsUsers__Header-SearchBox--Input {
    width: 99%;
    padding: 0 0.5rem;
    line-height: 2rem;
  
    background: #f2f2f2;
  
    border: none;
    border-radius: 8px;
    outline: none;
  
    background-image: url('../../Home/assets/icon-lupa.svg');
    background-position: 10px center;
    background-repeat: no-repeat;
    text-indent: 1.875rem;
    font-size: 1rem;
  }
  
  .SettingsUsers__Main {
    height: 65.5vh;
    overflow: auto;
  }
  
  .SettingsUsers__Main-Table {
    width: 100%;
  
    border-collapse: separate;
    border-spacing: 0;
  
    position: sticky;
  }
  
  .SettingsUsers__Main-Table--icon {
    position: relative;
  }
  
  .SettingsUsers__Main-Table--icon > img,
  .SettingsUsers__Filter--Container,
  .SettingsUsers__FilterActive {
    cursor: pointer;
  }
  
  .SettingsUsers__Main-Table thead {
    line-height: 2rem;
  }
  
  .SettingsUsers__Main-Table thead th {
    padding-left: 0.5rem;
    color: #838387;
  
    text-align: inherit;
    font-weight: normal;
    font-size: 0.9375rem;
  
    border-bottom: 0.2px solid #e2e2e2;
  
    background-color: #fff;
  
    position: sticky;
    position: -webkit-sticky;
  
    top: 0;
    z-index: 2;
  }
  
  .SettingsUsers__Main-Table thead th:last-child {
    padding-left: 0.8rem;
  }
  
  .SettingsUsers__Filter {
    position: relative;
  }
  
  @keyframes animarFilterOut {
    to {
      transform: initial;
      opacity: 1;
    }
    from {
      transform: translateX(150px);
      opacity: 0;
    }
  }
  
  .SettingsUsers__FilterActive.Filter__AnimaLeft {
    animation: animarFilterOut 0.5s forwards;
    display: flex;
  }
  
  .SettingsUsers__FilterActive {
    position: absolute;
    top: 5px;
    left: -65px;
    z-index: 999;
  
    display: flex;
  
    /* opacity: 0;
    transform: translateX(150px); */
    display: none;
  
    justify-content: center;
    align-items: center;
  
    color: white;
    background-color: #000;
  
    font-size: 10px;
    font-weight: bold;
    text-align: center;
  
    border-radius: 9px;
  
    line-height: 1rem;
  
    width: 4.75rem;
    height: 18px;
  }
  
  .SettingsUsers__Main-Table tbody tr {
    line-height: 3.5rem;
  }
  
  .SettingsUsers__Main-Table tbody tr td {
    color: #000;
    font-weight: 500;
    font-size: 1rem;
  
    padding-left: 0.5rem;
  }
  
  .SettingsUsers__Main-Table tbody tr td:first-child {
    display: flex;
    align-items: center;
  
    gap: 0.6rem;
  }
  
  .SettingsUsers__Main-Table tbody tr td:first-child img {
    width: 1.5625rem;
    height: auto;
  }
  
  .SettingsUsers__Main-Table tbody tr td:last-child {
    align-items: center;
  }
  
  .SettingsUsers__Main-Table--status {
    display: flex;
    justify-content: center;
    align-items: center;
  
    color: white;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
  
    border-radius: 9px;
  
    line-height: 1rem;
    width: 4.75rem;
  
    height: 18px;
  }
  
  .SettingsUsers__Main-Table tbody tr td div.SettingsUsers__Main-Table--inactive {
    background-color: #000;
  }
  
  .SettingsUsers__Main-Table tbody tr td div.SettingsUsers__Main-Table--active {
    background-color: #00b065;
  }
  
  .SettingsUsers__Main-Table
    tbody
    tr.SettingsUsers__Main-Table--inactive
    td:nth-child(1),
  .SettingsUsers__Main-Table
    tbody
    tr.SettingsUsers__Main-Table--inactive
    td:nth-child(2),
  .SettingsUsers__Main-Table
    tbody
    tr.SettingsUsers__Main-Table--inactive
    td:nth-child(3) {
    opacity: 0.7;
  }
  