/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap"); */
:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
}

body {
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #d4d4d4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaaaaa;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #505050;
}

.versao-sistema {
  position: fixed;
  text-align: left;
  font-size: 12px;
  font-weight: bolder;
  left: 0;
  bottom: 0;
  width: 7%;
  padding: 0 5px;
  color: #534545;
}

.not-allowed {
  cursor: not-allowed;
}

html,
body,
div,
applet,
object,
iframe,
h1,
h2,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@media (max-width: 700px) {
  .versao-sistema {
    position: static;
    width: 40%;
  }
}

@media (max-width: 580px) {
  .stepper {
    display: none;
  }
}
/* 
input{
  text-transform: uppercase;
} */

.Onboarding__AnimaLeft {
  opacity: 0;
  transform: translateX(-100px);
  animation: animarPage 1.5s forwards;
}

.Onboarding__AnimaRight {
  opacity: 0.5;
  transform: translateX(300px);
  animation: animarPage 2s forwards;
}

.Onboarding__AnimaDown {
  opacity: 0;
  transform: translateY(-25px);
  animation: animarPage 1s forwards;
}

.Onboarding__AnimaTop {
  opacity: 1;
  transform: translateY(600px);
  animation: animarPage 1s forwards;
}

.Main__AnimaLeft {
  opacity: 0;
  transform: translateX(-10px);
  animation: animarPage 1.5s forwards;
}
.Main__Fade {
  opacity: 0;
  transform: translateX(0px);
  animation: animarPage 0.5s forwards;
}

@keyframes animarPage {
  to {
    opacity: 1;
    transform: initial;
  }
}
