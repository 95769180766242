.bg_add {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  z-index: 100;
  box-sizing: border-box;
  padding: 5rem;
}

.templates_create_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modal__templates {
  background-color: #fff;
  position: relative;
  //height: 700px;
  //width: 69%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0000008e;
}

.close__modal {
  position: absolute;
  top: 10px;
  right: 15px;

  opacity: 0.7;

  cursor: pointer;
}

.templates__content {
  display: grid;
  grid-template-columns: 21rem 1fr;

  //height: 700px;
}

.templates__box {
  box-sizing: border-box;
  width: 800px;
  background-color: #fafafa;
  padding: 2.8125rem;
  border-radius: 0 10px 10px 0;
  height: 700px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}

.templates_list {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 3.5rem;

  /* background-color: red; */
}

.btn__container {
  display: flex;
  justify-content: flex-end;
  gap: 1.3rem;
  //padding: 1rem 0;
}
.btn_voltar {
  background: transparent;
  color: #969696;
  border: 0;
  font-weight: 700;
  font-size: 14px;
}

.btn_avancar {
  width: 7.5rem;
  height: 1.5625rem;
  border-radius: 5px;
  color: #fff;
  background: #0049c4;
  font-size: 0.875rem;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  outline: none;
  border: none;
}

.btn_avancar:disabled,
.btn_arquivar:disabled {
  opacity: 0.7;
}

.btn_arquivar {
  width: 7.5rem;
  height: 1.5625rem;
  border-radius: 5px;
  color: #fff;
  background: #930b24;
  font-size: 0.875rem;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  outline: none;
  border: none;
}

.text_editor_container {
  display: flex;
  flex-direction: column;
  /* background-color: blue; */
  height: 90%;
  margin-top: 2rem;
  gap: 0.5rem;
}

.inputs_templates {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input_template_title,
.input_template_subtitle {
  padding: 0.5rem;
  line-height: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: 0;
}

.input_template_title:focus,
.input_template_subtitle:focus {
  transition: 0.2s;
  border: 1px solid #0049c4;
  box-shadow: 0px 0px 4px #0048c438;
}

.editor__tags {
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: 70%;
  gap: 1rem;
  width: 100%;
}

.editor_container {
  min-width: 200px;
  flex: 1;
  width: 100%;
  //max-width: 480px;
}

.p-editor-toolbar,
.p-editor-content {
  background-color: #fff;
}

.p-editor-toolbar {
  border-radius: 7px 7px 0 0;
}

.p-editor-content {
  border-radius: 0 0 7px 7px;
}
/* 
.p-editor-container {
  width: 60%;
  max-width: 60%;
}

.p-editor-content,
.p-editor-toolbar {
  width: 100%;
  max-width: 100%;
} */

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background-color: #fff;
}

.textarea_sms {
  resize: none;
  width: 100%;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 7px;
  padding: 10px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  height: 466px;
  box-sizing: border-box;
}

.tag_list {
  background-color: #fff;
  height: fit-content;
  min-height: 433px;
  max-height: 433px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-x: hidden;
}

.tag_list::-webkit-scrollbar {
  display: none;
}

.tags_title {
  font-size: 1.1rem;
  font-weight: 700;
}

.tags_subtitle {
  font-size: 12px;
}

.tags_list_container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.items_accordion {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-top: 0.7rem;
}

.tag_item {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 0.2rem;
  height: 40px;
  border-radius: 7px;
  box-shadow: 0px 0px 4px #00000042;
  justify-content: center;
}

.tag_item .tag_item_title {
  font-weight: 700;
  font-size: 0.8rem;
  word-break: break-word;
}
.tag_item .tag_item_subtitle {
  font-size: 12px;
}

@media only screen and (max-width: 1450px) {
  .templates__content {
    grid-template-columns: 18rem 1fr;
    height: 100%;
  }

  .modal__templates {
    //height: 81%;
    //width: 85%;
  }

  .editor_container {
    min-width: 400px;
    //max-width: 460px;
    flex: 1;
  }
}
