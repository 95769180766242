.CompaniesMenuModal__Container {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;

  padding: 2.8125rem;
}

.CompaniesMenuModal__Container header {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.CompaniesMenuModal__Title {
  font-size: 1.375rem;
  font-weight: bold;
}

.CompaniesMenuModal__Subtitle {
  font-size: 0.75rem;
  font-weight: 300;
  max-width: 16.1875rem;
}

.CompaniesMenuModal__TimeLine ul {
  display: flex;
  flex-direction: column;

  height: 100%;
  gap: 1rem;
}

.CompaniesMenuModal__TimeLine ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.CompaniesMenuModal__TimeLine li {
  display: flex;
  align-items: center;

  padding: 0.5rem 1rem;
  border-radius: 8px;

  color: #c6c6c6;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: default;
}

.CompaniesMenuModal__TimeLine li.CompaniesMenuModal__TimeLine--selected,
.CompaniesMenuModal__TimeLine li.CompaniesMenuModal__TimeLine--selected:hover {
  background-color: #c8ddff;

  color: #0049c4;

  font-weight: bold;
}

.CompaniesMenuModal__TimeLine .CompaniesMenuModal__TimeLine--passed {
  background-color: #c7ffe7;
  color: #278a60;
  font-weight: bold;
}
